import { firestoreAction } from 'vuexfire';
import {db} from './../../fb'; 

const namespaced = true;
const state = {
    todos: [],
};
const getters = {
    getTodos(state) {
        return state.todos;
      },
    getTimeTodo: state => {
      let xzy = []
      for (let i = 0; i < state.todos.length; i++) {
        let factor = {}
        factor.termin = new Date(state.todos[i].date.seconds*1000)
        factor.description = 'Termin'
        factor.color = state.todos[i].color
       
        xzy.push(factor)
      }         
      return xzy
    },
    redTodoGetter(state, getters) {
      return getters.getTimeTodo.filter(function(c){return c.color.index === 0})
    },
    amberTodoGetter(state, getters) {
      return getters.getTimeTodo.filter(function(c){return c.color.index === 1})
    },
    greenTodoGetter(state, getters) {
      return getters.getTimeTodo.filter(function(c){return c.color.index === 2})
    },
    blueTodoGetter(state, getters) {
      return getters.getTimeTodo.filter(function(c){return c.color.index === 3})
    }
};
const mutations = {
    
      
};
const actions = {
  bindTodos: firestoreAction( (context) => {
    // return the promise returned by `bindFirestoreRef`
    
   var user = context.rootGetters['authStore/getUser']
   
    var uid = user.data.uid
//zeby polaczyc baze,zeby byly informacje w tym wypadku ponizej do szukania potrzebnych info   
    return context.bindFirestoreRef('todos', db.collection('todos').where('uid','==',uid))


}), 
//zeby rozlaczyc baze
  unbindProcesses: firestoreAction(({ unbindFirestoreRef }) => {
    return unbindFirestoreRef('todos');
    }),

  addTodo: firestoreAction((context,todo) => {
    var today = new Date();
    var gtmPlusTwo = parseInt(today.toJSON().slice(11,13).toString())+2;
    // return the promise so we can await the write
    var date = context.rootGetters['dateStore/getChoosenDate'];
    //console.log(context.rootState.authStore.user.data.uid)
    return db.collection('todos').add({
      date,
      time: todo.time,
      uid: context.rootState.authStore.user.data.uid,
      text: todo.text,
      color: todo.color,
      done: false,
      createdAt: today.toJSON().slice(8,10)+'.'+today.toJSON().slice(5,7)+'.'+today.toJSON().slice(0,4)+' '+gtmPlusTwo+':'+today.toJSON().slice(14,19)
    })
    
  }),     
  removeTodo:firestoreAction((context, todo) => {
        db.collection('todos')
          .doc(todo.id)
          .delete()
      }),      
};


export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
 }