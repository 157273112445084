<template>
<v-form ref="form">
    <v-dialog persistent v-model="showDialog" width="600">
        <v-card>
            <v-card-title :class="$vuetify.breakpoint.width >= 600 ? 'mx-4' : 'mx-2'">{{ choosenDate }}</v-card-title>
            <v-container>
                <v-row :class="$vuetify.breakpoint.width >= 600 ? 'mx-4' : 'mx-2'">
                  <v-col cols="12">         
                    <v-textarea
                        v-model="text"
                        label="Neuen Termin hinzufügen"
                        outlined
                        dense
                        rows="1"
                        auto-grow
                        autocomplete="off"
                        clearable
                        color="primary"
                        flat
                        hide-details
                        solo
                        append-icon="mdi-view-grid-plus-outline"
                        :rules="inputRules"
                      >
                        </v-textarea>
                    </v-col>
                    
                    </v-row>
                    <v-row :class="$vuetify.breakpoint.width >= 600 ? 'mx-4' : 'mx-2'">

                    <v-col cols="5" class="mt-0">
                    <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list>
                        <v-list-item class="pa-0" dense v-bind="attrs" v-on="on">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title >Priorität <v-icon size="15">mdi-chevron-down</v-icon></v-list-item-title>
                          <v-list-item-subtitle :class="`${color.val}--text`"><v-icon :color="color.val">
                          mdi-numeric-{{color.index+1}}-circle
                        </v-icon > {{ color.name }}</v-list-item-subtitle>
                        </v-list-item-content>                         
                        </v-list-item>
                      </v-list>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(color, index) in colors"
                        :key="index"
                        @click="setColor(color,index)"
                      >
                      <v-list-item-icon>
                        <v-icon :color="color.val">
                          mdi-numeric-{{index+1}}-circle
                        </v-icon ></v-list-item-icon>
                        <v-list-item-title :class="`${color.val}--text`">{{ color.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  </v-col>
                    <v-col class="mt-0" cols="7">
      <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time" 
            class="pa-0"
            label="Uhrzeit wählen"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="inputRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="modal2"
          v-model="time"
          full-width
          format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(time)"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
                    </v-col>
                    </v-row>

            <v-row>
              <TodosList/> 
            </v-row>        
            </v-container>       
          
            <v-card-actions>
                <v-btn :class="$vuetify.breakpoint.width >= 600 ? 'mx-4 mb-4' : 'mx-2 mb-2'" color="primary" @click="addNewTodo">Hinzufügen</v-btn>
                <v-spacer></v-spacer>
                <v-btn :class="$vuetify.breakpoint.width >= 600 ? 'mx-4 mb-4 hightlight--text' : 'mx-2 mb-2 hightlight--text'" text @click="closeDialog">Schließen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import TodosList from '@/components/todos/TodosList'
export default{
    name: 'DateDetailView',
    components:{
      TodosList
    },
    computed: {
        showDialog() {
            return this.$store.getters['uiStore/getShowDialog'];
        },
        choosenDate() {
            return this.$store.getters['dateStore/getChoosenDate'].toLocaleDateString();
        }
    },
    methods: {
        closeDialog(){
           this.$store.dispatch('uiStore/hideDialog').then(() => {
             this.$refs.form.reset()
             this.setColor({ name: 'Sehr Hoch', val: 'red' },0)
             })
            //return this.$store.dispatch('uiStore/hideDialog');
        },
        addNewTodo() {
            if(this.$refs.form.validate()){
                this.$store.dispatch('todoStore/addTodo', {
                time: this.time,
                text: this.text,
                color: this.color,
            }).then(() => {
          this.$refs.form.reset()
          this.setColor({ name: 'Sehr Hoch', val: 'red' },0)
          })

            } else {
              setTimeout(() => {
            this.$refs.form.resetValidation()
          }, 2500);
            };
        },
        setColor(color,index) {
          this.color = color
          this.color.index = index
        }
    },
    data() { 
    return {
      text: '',
      time: null,
      modal2: false,
      color: {
        name: 'Sehr Hoch', val: 'red', index:0
      },

        colors: [
        { name: 'Sehr Hoch', val: 'red' },
        { name: 'Hoch', val: 'amber' },
        { name: 'Normal', val: 'green' },
        { name: 'Gering', val: 'blue' },
        
      ],
      inputRules: [
        v => !!v || 'Dies ist ein Pflichtfeld'
      ],
    }
    },

}
</script>
