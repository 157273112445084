<template>
    <v-app>
    <v-row align="center" justify="center">
      <v-card
        class="mx-auto"
        width="620"
        height="auto"
        color='#EAEAEA'
     >
        <v-card-title class="justify-center">Registrieren</v-card-title>
        <v-card-text class="px-9">
          Hier können Sie einen Account erstellen, um die Termine zu speichern.
          Die Nutzung ist kostenlos und entspricht den DSGVO. Weitere Informationen erhalten Sie aus unserer <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="https://www.slenderiser.de/datenschutzerklaerung/"
                        @click.stop
                        v-on="on"
                      >
                        Datenschutzerklärung.
                      </a>
                    </template>
                    Öffnet in einem neuen Fenster
                  </v-tooltip>
        </v-card-text>
        <v-form ref="form">
          <v-text-field
                  label="E-Mail"
                  dense solo flat
                  v-model="email"
                  :rules="emailRules"
                  type="email"
                  id="email"
                  class="mx-9"
                  required
          />
          <v-text-field
                  label="Name"
                  dense solo flat
                  v-model="name"
                  :rules="inputRules"
                  type="text"
                  id="name"
                  class="mx-9"
                  required
          />
          <v-text-field
                  label="Passwort"
                  dense solo flat
                  v-model="password"
                  :rules="passwordRules"
                  type="password"
                  id="password"
                  class="mx-9"
                  required
          />
          <v-text-field
                  label="Passwort Bestätigen"
                  dense solo flat
                  v-model="confPassword"
                  :rules="passwordRules.concat(passwordConfirmationRule)"
                  type="password"
                  id="confPassword"
                  class="mx-9"
                  required
          />

        <v-checkbox v-model="checkbox" class="mx-9"
        true-value="1"
        false-value="0"
        :rules="chkBox"
        >
              <template v-slot:label>
                <div>
                  Ich akzeptiere die 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="https://www.slenderiser.de/datenschutzerklaerung/"
                        @click.stop
                        v-on="on"
                      >
                        Datenschutzbestimmungen.
                      </a>
                    </template>
                    Öffnet in einem neuen Fenster
                  </v-tooltip>
                </div>
              </template>
        </v-checkbox>
        <v-row class="mx-9 mb-4 mt-2">
            <v-btn class=" highlight--text" color="primary" @click="confirm">Bestätigen</v-btn>
            <v-spacer></v-spacer>  
            <v-btn class="highlight--text" text @click="login">Ich habe bereits einen Account</v-btn>
        </v-row>
        </v-form>   
      </v-card>
      <MsgSnackbar/>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :value="value" 
      light 
    >
          <div>Es ist ein Fehler aufgetreten:</div>
          <div class="">-E-Mailadresse unvollständig</div>
          <div class="">-Username unvollständig</div>
          <div>-Passwörter stimmen nicht überein,</div>
          <div>-Passwort ist zu kurz,</div>
          -Datenschutzbedingungen wurden nicht akzeptiert.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#a0ce4e"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
         Schließen
        </v-btn>
      </template>
     <v-progress-linear
      absolute
      bottom
      :value="Math.floor(100 * (currentTime / timeout))"
    />
    </v-snackbar>
   </v-app>
</template>

<script>
import MsgSnackbar from '@/components/MsgSnackbar'
export default {
    name: 'SignUp',
     components:{
    MsgSnackbar
  },
    methods: {
      login(){
      this.$router.push("login")
    },
      confirm() {
        if(this.$refs.form.validate()){
          this.$store.dispatch('authStore/signUp', {email:this.email, password:this.password, name: this.name})
        }
        else{
          setTimeout(() => {
            this.$refs.form.resetValidation()
          }, 3000);
          this.snackbar = true;
        }
      },
       syncPbar() {
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //Increment the time counter by 100
        this.currentTime += 100;
        //If our current time is larger than the timeout
        if (this.timeout <= this.currentTime) {
          //Wait 300 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.$emit("input", false); //Update the v-model to false
            this.currentTime = 0; // reset the current time
          }, 300);
        } else {
          //Recursivly update the progress bar
          this.syncPbar();
        }
      }, 100);
    },
  },
  watch: {
    snackbar(v) {
      if (v) this.syncPbar();
                },
      },
  props: {
    timeout: {
      default: 4 * 1000,
            },
    value: {
      default: false,
           },
  },
    data() {
        return {   
        email: "",
        password: "",
        confPassword: "",
        name:"",
        checkbox: [],
        snackbar: false,
        multiLine: true,
        currentTime: 0,
        inputRules: [
          v => !!v || 'Dies ist ein Pflichtfeld',
          v => v.length > 2 || 'Die Eingabe muss mindestens 3 Zeichen enthalten'
        ],
        passwordRules: [
          v => !!v || 'Dies ist ein Pflichtfeld',
          v => v.length > 5 || 'Das Passwort muss mindestens 6 Zeichen enthalten'
        ],
        emailRules: [
        v => !!v || 'Dies ist ein Pflichtfeld',
        v => /.+@.+/.test(v) || 'Die E-Mail muss gültig sein',
        ],
    };
    },
    computed: {
    chkBox () {
      return [this.checkbox.length > 0 || "Sie mussen die Datenschutzbestimmungen akzeptieren"]
    },
    passwordConfirmationRule() {
      return [this.password === this.confPassword || "Passwörter müssen gleich sein"]
    }
  },
}
</script>