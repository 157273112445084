<template>
  <v-container class="text--primary">
    <v-row class="mx-4" v-for="(todo, i) in todos" :key="i">
      <v-col lg="10" sm="9" cols="9">
        <ShowTodo :todo="todo"/>
      </v-col>
      <v-col lg="2" sm="3" cols="3">
        <RemoveTodo :todo="todo"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="text/ecmascript-6">
  import { mapActions } from 'vuex'
  import ShowTodo from './ShowTodo'
  import RemoveTodo from './RemoveTodo'

  export default {
    components:{
      ShowTodo,
      RemoveTodo
    },
    data() { 
      return {
        // todos: []

      }
    },
    methods: {
      ...mapActions('todoStore',[
        'removeTodo',
      ]),

    },
    computed: {
      todos() {
       
        const todos = this.$store.getters['todoStore/getTodos'];
        const choosenDate = this.$store.getters['dateStore/getChoosenDate']
        return todos.filter(function(t){
          return new Date(t.date.seconds*1000).toLocaleDateString() === choosenDate.toLocaleDateString()
          },choosenDate).sort((a,b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0))
      }
    }
  }
</script>

