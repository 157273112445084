<template>
    <v-container>
      <v-row>
        <v-col >
          <!-- CALENDAR -->
          <vc-date-picker
            ref="picker"
            :attributes="attributes"
            is-expanded
            :value="null"
            is-inline
            :step="1"
            :columns="layout.columns"
            :rows="layout.rows"
            :is-dark="$vuetify.theme.dark"
            @input="onDateSelect"
          />
        </v-col>       
      </v-row>
        <date-detail-view />
    </v-container>  
</template>

<script>

import DateDetailView from '@/components/DateDetailView';

export default {
  name: 'Calendar',
  components: {
    DateDetailView
  },
  data () {
    return {
     }
  },
  methods: {
    moveThreeMonths(){
        this.$refs.picker.$refs.calendar.move(-2)
    },
    onDateSelect($event) {
      console.log($event);
      this.$store.dispatch('dateStore/setDate', $event)
      this.$store.dispatch('uiStore/showDialog')
    },
  },
  created() {
  },
  mounted(){
    //initalize the calendar and set the right view to see all relevant months
      this.moveThreeMonths();
    // override default with custom settings
  },
  computed: {
    layout() {
      return this.$screens(
        {
          // Default layout for mobile
          default: {
            columns: 1,
            rows: 4,
          },
          // Override for large screens
          lg: {
            columns: 2,
            rows: 2,
          },
        },
      );
      },
      //Calendar Attributes
    attributes() {
      return this.$store.getters['dateStore/attributes'];
    } // End Calendar Attributes
    

  }, // End computed properties
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
.vc-bars .vc-bar {
    height: 6px !important;
}
</style>