//Quelle https://www.dgb.de/gesetzliche-feiertage-deutschland-2020-2021#liste
const bdewDates = [
  { holidayDate: "2020-06-01", description: "Pfingstmontag" },
  { holidayDate: "2020-06-02", description: "" },
  { holidayDate: "2020-06-03", description: "" },
  { holidayDate: "2020-06-06", description: "" },
  { holidayDate: "2020-06-07", description: "" },
  { holidayDate: "2020-06-11", description: "Fronleichnam" },
  { holidayDate: "2020-06-13", description: "" },
  { holidayDate: "2020-06-14", description: "" },
  { holidayDate: "2020-06-20", description: "" },
  { holidayDate: "2020-06-21", description: "" },
  { holidayDate: "2020-06-27", description: "" },
  { holidayDate: "2020-06-28", description: "" },
  { holidayDate: "2020-07-04", description: "" },
  { holidayDate: "2020-07-05", description: "" },
  { holidayDate: "2020-07-11", description: "" },
  { holidayDate: "2020-07-12", description: "" },
  { holidayDate: "2020-07-18", description: "" },
  { holidayDate: "2020-07-19", description: "" },
  { holidayDate: "2020-07-25", description: "" },
  { holidayDate: "2020-07-26", description: "" },
  { holidayDate: "2020-08-01", description: "" },
  { holidayDate: "2020-08-02", description: "" },
  { holidayDate: "2020-08-08", description: "" },
  { holidayDate: "2020-08-09", description: "" },
  { holidayDate: "2020-08-15", description: "Mariä Himmelfahrt" },
  { holidayDate: "2020-08-16", description: "" },
  { holidayDate: "2020-08-22", description: "" },
  { holidayDate: "2020-08-23", description: "" },
  { holidayDate: "2020-08-29", description: "" },
  { holidayDate: "2020-08-30", description: "" },
  { holidayDate: "2020-09-05", description: "" },
  { holidayDate: "2020-09-06", description: "" },
  { holidayDate: "2020-09-12", description: "" },
  { holidayDate: "2020-09-13", description: "" },
  { holidayDate: "2020-09-19", description: "" },
  { holidayDate: "2020-09-20", description: "Weltkindertag" },
  { holidayDate: "2020-09-26", description: "" },
  { holidayDate: "2020-09-27", description: "" },
  { holidayDate: "2020-10-03", description: "Tag der dt. Einheit" },
  { holidayDate: "2020-10-04", description: "" },
  { holidayDate: "2020-10-10", description: "" },
  { holidayDate: "2020-10-11", description: "" },
  { holidayDate: "2020-10-17", description: "" },
  { holidayDate: "2020-10-18", description: "" },
  { holidayDate: "2020-10-24", description: "" },
  { holidayDate: "2020-10-25", description: "" },
  { holidayDate: "2020-10-31", description: "Reformationstag" },
  { holidayDate: "2020-11-01", description: "Allerheiligen" },
  { holidayDate: "2020-11-07", description: "" },
  { holidayDate: "2020-11-08", description: "" },
  { holidayDate: "2020-11-14", description: "" },
  { holidayDate: "2020-11-15", description: "" },
  { holidayDate: "2020-11-18", description: "Buß-und Bettag" },
  { holidayDate: "2020-11-21", description: "" },
  { holidayDate: "2020-11-22", description: "" },
  { holidayDate: "2020-11-28", description: "" },
  { holidayDate: "2020-11-29", description: "1. Advent" },
  { holidayDate: "2020-12-05", description: "" },
  { holidayDate: "2020-12-06", description: "2. Advent" },
  { holidayDate: "2020-12-12", description: "" },
  { holidayDate: "2020-12-13", description: "3. Advent" },
  { holidayDate: "2020-12-19", description: "" },
  { holidayDate: "2020-12-20", description: "4. Advent" },
  { holidayDate: "2020-12-24", description: "Heiligabend" },
  { holidayDate: "2020-12-25", description: "1. Weihnachtstag" },
  { holidayDate: "2020-12-26", description: "2. Weihnachtstag" },
  { holidayDate: "2020-12-27", description: "" },
  { holidayDate: "2020-12-31", description: "Silvester" },
  { holidayDate: "2021-01-01", description: "Neujahr" },
  { holidayDate: "2021-01-02", description: "" },
  { holidayDate: "2021-01-03", description: "" },
  { holidayDate: "2021-01-09", description: "" },
  { holidayDate: "2021-01-10", description: "" },
  { holidayDate: "2021-01-16", description: "" },
  { holidayDate: "2021-01-17", description: "" },
  { holidayDate: "2021-01-23", description: "" },
  { holidayDate: "2021-01-24", description: "" },
  { holidayDate: "2021-01-30", description: "" },
  { holidayDate: "2021-01-31", description: "" },
  { holidayDate: "2021-02-06", description: "" },
  { holidayDate: "2021-02-07", description: "" },
  { holidayDate: "2021-02-13", description: "" },
  { holidayDate: "2021-02-14", description: "" },
  { holidayDate: "2021-02-20", description: "" },
  { holidayDate: "2021-02-21", description: "" },
  { holidayDate: "2021-02-27", description: "" },
  { holidayDate: "2021-02-28", description: "" },
  { holidayDate: "2021-03-06", description: "" },
  { holidayDate: "2021-03-07", description: "" },
  { holidayDate: "2021-03-13", description: "" },
  { holidayDate: "2021-03-14", description: "" },
  { holidayDate: "2021-03-20", description: "" },
  { holidayDate: "2021-03-21", description: "" },
  { holidayDate: "2021-03-27", description: "" },
  { holidayDate: "2021-03-28", description: "" },
  { holidayDate: "2021-04-02", description: "Karfreitag" },
  { holidayDate: "2021-04-03", description: "" },
  { holidayDate: "2021-04-04", description: "Ostersonntag" },
  { holidayDate: "2021-04-05", description: "Ostermontag" },
  { holidayDate: "2021-04-10", description: "" },
  { holidayDate: "2021-04-11", description: "" },
  { holidayDate: "2021-04-17", description: "" },
  { holidayDate: "2021-04-18", description: "" },
  { holidayDate: "2021-04-24", description: "" },
  { holidayDate: "2021-04-25", description: "" },
  { holidayDate: "2021-05-01", description: "Tag der Arbeit" },
  { holidayDate: "2021-05-02", description: "" },
  { holidayDate: "2021-05-08", description: "" },
  { holidayDate: "2021-05-09", description: "" },
  { holidayDate: "2021-05-13", description: "Christi Himmelfahrt" },
  { holidayDate: "2021-05-15", description: "" },
  { holidayDate: "2021-05-16", description: "" },
  { holidayDate: "2021-05-22", description: "" },
  { holidayDate: "2021-05-23", description: "Pfingstsonntag" },
  { holidayDate: "2021-05-24", description: "Pfingstmontag" },
  { holidayDate: "2021-05-29", description: "" },
  { holidayDate: "2021-05-30", description: "" },
  { holidayDate: "2021-06-03", description: "Fronleichnam" },
  { holidayDate: "2021-06-05", description: "" },
  { holidayDate: "2021-06-06", description: "" },
  { holidayDate: "2021-06-12", description: "" },
  { holidayDate: "2021-06-13", description: "" },
  { holidayDate: "2021-06-19", description: "" },
  { holidayDate: "2021-06-20", description: "" },
  { holidayDate: "2021-06-26", description: "" },
  { holidayDate: "2021-06-27", description: "" },
  { holidayDate: "2021-07-03", description: "" },
  { holidayDate: "2021-07-04", description: "" },
  { holidayDate: "2021-07-10", description: "" },
  { holidayDate: "2021-07-11", description: "" },
  { holidayDate: "2021-07-17", description: "" },
  { holidayDate: "2021-07-18", description: "" },
  { holidayDate: "2021-07-24", description: "" },
  { holidayDate: "2021-07-25", description: "" },
  { holidayDate: "2021-07-31", description: "" },
  { holidayDate: "2021-08-01", description: "" },
  { holidayDate: "2021-08-07", description: "" },
  { holidayDate: "2021-08-08", description: "" },
  { holidayDate: "2021-08-14", description: "" },
  { holidayDate: "2021-08-15", description: "Mariä Himmelfahrt" },
  { holidayDate: "2021-08-21", description: "" },
  { holidayDate: "2021-08-22", description: "" },
  { holidayDate: "2021-08-28", description: "" },
  { holidayDate: "2021-08-29", description: "" },
  { holidayDate: "2021-09-04", description: "" },
  { holidayDate: "2021-09-05", description: "" },
  { holidayDate: "2021-09-11", description: "" },
  { holidayDate: "2021-09-12", description: "" },
  { holidayDate: "2021-09-18", description: "" },
  { holidayDate: "2021-09-19", description: "" },
  { holidayDate: "2021-09-20", description: "Weltkindertag" },
  { holidayDate: "2021-09-25", description: "" },
  { holidayDate: "2021-09-26", description: "" },
  { holidayDate: "2021-10-02", description: "" },
  { holidayDate: "2021-10-03", description: "Tag der dt. Einheit" },
  { holidayDate: "2021-10-09", description: "" },
  { holidayDate: "2021-10-10", description: "" },
  { holidayDate: "2021-10-16", description: "" },
  { holidayDate: "2021-10-17", description: "" },
  { holidayDate: "2021-10-23", description: "" },
  { holidayDate: "2021-10-24", description: "" },
  { holidayDate: "2021-10-30", description: "" },
  { holidayDate: "2021-10-31", description: "Reformationstag" },
  { holidayDate: "2021-11-01", description: "Allerheiligen" },
  { holidayDate: "2021-11-06", description: "" },
  { holidayDate: "2021-11-07", description: "" },
  { holidayDate: "2021-11-13", description: "" },
  { holidayDate: "2021-11-14", description: "" },
  { holidayDate: "2021-11-20", description: "" },
  { holidayDate: "2021-11-21", description: "" },
  { holidayDate: "2021-11-27", description: "" },
  { holidayDate: "2021-11-28", description: "1. Advent" },
  { holidayDate: "2021-12-04", description: "" },
  { holidayDate: "2021-12-05", description: "2. Advent" },
  { holidayDate: "2021-12-11", description: "" },
  { holidayDate: "2021-12-12", description: "3. Advent" },
  { holidayDate: "2021-12-18", description: "" },
  { holidayDate: "2021-12-19", description: "4. Advent" },
  { holidayDate: "2021-12-24", description: "Heiligabend" },
  { holidayDate: "2021-12-25", description: "1. Weihnachtstag" },
  { holidayDate: "2021-12-26", description: "2. Weihnachtstag" },
  { holidayDate: "2021-12-31", description: "Silvester" },
  { holidayDate: "2022-01-01", description: "Neujahr" },
  { holidayDate: "2022-01-02", description: "" },
  { holidayDate: "2022-01-08", description: "" },
  { holidayDate: "2022-01-09", description: "" },
  { holidayDate: "2022-01-15", description: "" },
  { holidayDate: "2022-01-16", description: "" },
  { holidayDate: "2022-01-22", description: "" },
  { holidayDate: "2022-01-23", description: "" },
  { holidayDate: "2022-01-29", description: "" },
  { holidayDate: "2022-01-30", description: "" },
  { holidayDate: "2022-02-05", description: "" },
  { holidayDate: "2022-02-06", description: "" },
  { holidayDate: "2022-02-12", description: "" },
  { holidayDate: "2022-02-13", description: "" },
  { holidayDate: "2022-02-19", description: "" },
  { holidayDate: "2022-02-20", description: "" },
  { holidayDate: "2022-02-26", description: "" },
  { holidayDate: "2022-02-27", description: "" },
  { holidayDate: "2022-03-05", description: "" },
  { holidayDate: "2022-03-06", description: "" },
  { holidayDate: "2022-03-12", description: "" },
  { holidayDate: "2022-03-13", description: "" },
  { holidayDate: "2022-03-19", description: "" },
  { holidayDate: "2022-03-20", description: "" },
  { holidayDate: "2022-03-26", description: "" },
  { holidayDate: "2022-03-27", description: "" },
  { holidayDate: "2022-04-02", description: "" },
  { holidayDate: "2022-04-03", description: "" },
  { holidayDate: "2022-04-09", description: "" },
  { holidayDate: "2022-04-10", description: "" },
  { holidayDate: "2022-04-15", description: "Karfreitag" },
  { holidayDate: "2022-04-17", description: "Ostersonntag" },
  { holidayDate: "2022-04-18", description: "Ostermontag" },
  { holidayDate: "2022-04-16", description: "" },
  { holidayDate: "2022-04-17", description: "" },
  { holidayDate: "2022-04-23", description: "" },
  { holidayDate: "2022-04-24", description: "" },
  { holidayDate: "2022-04-30", description: "" },
  { holidayDate: "2022-05-01", description: "Tag der Arbeit" },
  { holidayDate: "2022-05-07", description: "" },
  { holidayDate: "2022-05-08", description: "" },
  { holidayDate: "2022-05-14", description: "" },
  { holidayDate: "2022-05-15", description: "" },
  { holidayDate: "2022-05-21", description: "" },
  { holidayDate: "2022-05-22", description: "" },
  { holidayDate: "2022-05-26", description: "Christi Himmelfahrt" },
  { holidayDate: "2022-05-28", description: "" },
  { holidayDate: "2022-05-29", description: "" },
  { holidayDate: "2022-06-04", description: "" },
  { holidayDate: "2022-06-05", description: "Pfingstsonntag" },
  { holidayDate: "2022-06-06", description: "Pfingstmontag" },
  { holidayDate: "2022-06-11", description: "" },
  { holidayDate: "2022-06-12", description: "" },
  { holidayDate: "2022-06-16", description: "Fronleichnam" },
  { holidayDate: "2022-06-18", description: "" },
  { holidayDate: "2022-06-19", description: "" },
  { holidayDate: "2022-06-25", description: "" },
  { holidayDate: "2022-06-26", description: "" },
  { holidayDate: "2022-07-02", description: "" },
  { holidayDate: "2022-07-03", description: "" },
  { holidayDate: "2022-07-09", description: "" },
  { holidayDate: "2022-07-10", description: "" },
  { holidayDate: "2022-07-16", description: "" },
  { holidayDate: "2022-07-17", description: "" },
  { holidayDate: "2022-07-23", description: "" },
  { holidayDate: "2022-07-24", description: "" },
  { holidayDate: "2022-07-30", description: "" },
  { holidayDate: "2022-07-31", description: "" },
  { holidayDate: "2022-08-06", description: "" },
  { holidayDate: "2022-08-13", description: "" },
  { holidayDate: "2022-08-14", description: "" },
  { holidayDate: "2022-08-15", description: "Mariä Himmelfahrt" },
  { holidayDate: "2022-08-20", description: "" },
  { holidayDate: "2022-08-21", description: "" },
  { holidayDate: "2022-08-27", description: "" },
  { holidayDate: "2022-08-28", description: "" },
  { holidayDate: "2022-09-03", description: "" },
  { holidayDate: "2022-09-04", description: "" },
  { holidayDate: "2022-09-10", description: "" },
  { holidayDate: "2022-09-11", description: "" },
  { holidayDate: "2022-09-17", description: "" },
  { holidayDate: "2022-09-18", description: "" },
  { holidayDate: "2022-09-20", description: "Weltkindertag" },
  { holidayDate: "2022-09-24", description: "" },
  { holidayDate: "2022-09-25", description: "" },
  { holidayDate: "2022-10-01", description: "" },
  { holidayDate: "2022-10-02", description: "" },
  { holidayDate: "2022-10-03", description: "Tag der dt. Einheit" },
  { holidayDate: "2022-10-08", description: "" },
  { holidayDate: "2022-10-09", description: "" },
  { holidayDate: "2022-10-15", description: "" },
  { holidayDate: "2022-10-16", description: "" },
  { holidayDate: "2022-10-22", description: "" },
  { holidayDate: "2022-10-23", description: "" },
  { holidayDate: "2022-10-29", description: "" },
  { holidayDate: "2022-10-30", description: "" },
  { holidayDate: "2022-10-31", description: "Reformationstag" },
  { holidayDate: "2022-11-01", description: "Allerheiligen" },
  { holidayDate: "2022-11-05", description: "" },
  { holidayDate: "2022-11-06", description: "" },
  { holidayDate: "2022-11-12", description: "" },
  { holidayDate: "2022-11-13", description: "" },
  { holidayDate: "2022-11-19", description: "" },
  { holidayDate: "2022-11-20", description: "" },
  { holidayDate: "2022-11-26", description: "" },
  { holidayDate: "2022-11-27", description: "" },
  { holidayDate: "2022-12-03", description: "" },
  { holidayDate: "2022-12-04", description: "1. Advent" },
  { holidayDate: "2022-12-10", description: "" },
  { holidayDate: "2022-12-11", description: "2. Advent" },
  { holidayDate: "2022-12-17", description: "" },
  { holidayDate: "2022-12-18", description: "3. Advent" },
  { holidayDate: "2022-12-24", description: "Heiligabend" },
  { holidayDate: "2022-12-25", description: "1. Weihnachtstag" },
  { holidayDate: "2022-12-26", description: "2. Weihnachtstag" },
  { holidayDate: "2022-12-31", description: "Silvester" },

 
]

export default bdewDates