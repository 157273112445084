var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right ",attrs:{"icon":"","color":"grey"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Notiz löschen ")]),_c('v-card-text',[_c('div',[_vm._v(" Sie sind im Begriff eine Notiz zu löschen. Möchten Sie diese Notiz unwiderruflich löschen? ")])]),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mb-5 mx-auto",attrs:{"fab":"","dark":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.deleteTodo(_vm.todo)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Löschen bestätigen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mb-5 mx-auto",attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}])},[_c('span',[_vm._v("Abbrechen")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }