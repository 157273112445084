<template>
    <div class="">
        <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
          <div v-if="todo.text.length > 37" >
            <v-icon :color="todo.color.val">
                          mdi-numeric-{{todo.color.index+1}}-circle
                        </v-icon >
              {{todo.time}} {{cutTodoText}}
          <v-btn
          text
          small       
          color="#009dce"
          dark
          v-bind="attrs"
          v-on="on"
          class="pl-0"
          >
          Mehr lesen
        </v-btn>
          </div>
          <div v-else >
              <v-icon :color="todo.color.val">
                          mdi-numeric-{{todo.color.index+1}}-circle
                        </v-icon >
              {{todo.time}}
              {{todo.text}}
          </div>       
      </template>
        <v-card class="pa-0" elevation="0">
              <v-card-title>
                  Termin für {{todo.time}}
              </v-card-title>
              <v-card-text>
                <v-textarea auto-grow readonly outlined :value="[todo.text]">
                 </v-textarea>

                 </v-card-text>
              <v-card-actions>
                <!-- <v-text-field class="mx-2" filled label="Notiz wurde erstellt am:" readonly outlined :value="todo.createdAt"></v-text-field>  -->
                <RemoveTodo @closeMe="dialog = false" :todo="todo"/>                
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
 
 import RemoveTodo from './RemoveTodo'
  export default {
    props:['todo'],
    components:{
      RemoveTodo
    },
    data() { 
      return {
          dialog: false
        // todos: []

      }
    },
    computed: {
      cutTodoText(){
          
          const val = this.todo.text.substr(0,37)+"..."
          return val
      }
    }
  }
</script>