import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire';
import authStore from './modules/auth/authStore.js';
import dateStore from './modules/dates/dateStore.js';
import todoStore from './modules/todos/todoStore.js';
import uiStore from './modules/ui/uiStore';

Vue.use(Vuex)

export default  new Vuex.Store({

  state: {
    
  },
  
  getters: {
    
  },
  
  mutations: {
    // other mutations
    ...vuexfireMutations,
    //to zostalo tutaj dodane zeby mozna to bylo we wszystkich storach uzywac tego vuex dziwadla
  },

  actions: {
      
  },

  modules: {
    authStore,
    dateStore,
    todoStore,
    uiStore
  },
  
}) 
