<template>
    <v-app>
  <v-row align="center" justify="center">  
      <v-card
        class="mx-auto"
        width="620"
        height="auto"
        color='#EAEAEA'
      >
      <v-form ref="form">
        <v-card-title class="justify-center text-uppercase">Passwort Zurücksetzen</v-card-title>
        <v-card-text class="mx-4">Sie haben ihr Passwort vergessen? Geben Sie ihre Emailadresse ein um einen Link zum Zurücksetzen zu erhalten</v-card-text>
        <v-text-field
        class="mx-9"
        name="email"
        label="E-Mail"
        id="email"
        v-model="email"
        type="email"
        placeholder=" "
        persistent-placeholder
        :rules="emailRules"
        ></v-text-field>
        <v-row class="mx-4 mb-4 mt-2">
        <v-btn class="mx-4 mb-4" color="primary" @click="newPassword">Zurücksetzen</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mx-4 mb-4 highlight--text" text @click="login">Anmelden</v-btn>
        </v-row>
        </v-form>
      </v-card>

   
  <MsgSnackbar/>
  </v-row> 
      
      
  </v-app> 
</template>

<script>
import MsgSnackbar from '@/components/MsgSnackbar'
export default {
    components:{
    MsgSnackbar
  },
    name:'ResetPassword',
    data() {
    return {
        email: "",
        emailRules: [
        v => !!v || 'Dies ist ein Pflichtfeld',
        v => /.+@.+/.test(v) || 'Die E-Mail muss gültig sein',
      ],
    };
  },
    methods:{
        newPassword(){
             if(this.$refs.form.validate()){
        this.$store.dispatch('authStore/resetPassword',{email:this.email});
        }
      else{
          setTimeout(() => {
            this.$refs.form.resetValidation()
          }, 2000);        
        }  
        

    },
    login(){
        this.$router.push('/login')
    }
    }

}
</script>