import Vue from 'vue'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import firebase from "firebase/app";
import 'firebase/auth';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar because of Vuetify />
});

Vue.config.productionTip = false
/*var dark = false;
if (
window.matchMedia &&
window.matchMedia("(prefers-color-scheme: dark)").matches
) {
dark = true;
}
//dark = false;*/

router.beforeEach((to, from, next) => {  
  const currentUser = firebase.auth().currentUser;  
  const requiresAuth = to.meta.requiresAuth;// matched.some(record => record.meta.requiresAuth);  
  if (requiresAuth && !currentUser) next({ name: 'Login' });  
  else if (!requiresAuth && currentUser) next('dashboard');  
  else next();
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  // created() {
  //   // Prevent blank screen in Electron builds
  //   this.$router.push('/')
  // }
}).$mount('#app')
