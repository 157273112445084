import firebase from "firebase/app";
import 'firebase/auth';
import router from '@/router';

const state = { 
    user: {
        data: null
      },
      newLogin: false,
      msgSnackbar: false,
      msgSnackbarText:'',
      msgSnackbarColor:'',

};
const getters = { 
    getUser(state){     
      return state.user
    },
    userName(state) {
      return firebase.auth().currentUser.displayName 
    },
    getLoginStatus(state){
      return state.newLogin
    },
    getMsgSnackbar(state) {
      console.log(state.msgSnackbar)
      return state.msgSnackbar
    },
    getMsgSnackbarText(state) {
      return state.msgSnackbarText
    },
    getMsgSnackbarColor(state) {
      return state.msgSnackbarColor
    },


  };
const mutations = { 
      SET_USER(state, data) {
        state.user.data = data;       
      },
      SET_LOGINSTATUS(state){
        state.newLogin = !state.newLogin
      },
      SET_MSGSNACKBAR(state){
        state.msgSnackbar = !state.msgSnackbar
      },
      SET_MSGSNACKBAR_TEXT(state,val){
        state.msgSnackbarText = val      
      },
      SET_MSGSNACKBAR_COLOR(state,val){
        state.msgSnackbarColor = val
      },
    
      
};
const actions = { 
    logIn({ dispatch,commit }){
      //Start Loading animantion
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            dispatch('uiStore/startPageLoading', 'store', {root:true})
            // if user is logged in, bind firebase data and load page to current route
          
          commit('SET_USER', user);
       
          //Biniding Todos to user
          await dispatch('todoStore/bindTodos',null, {root:true});
          await dispatch('dateStore/loadBdewDates', null, {root:true})
          //...
          //console.log("logged IN")#
          dispatch('uiStore/stopPageLoading', 'store', {root:true})
          router.push('/');
          } else {
            console.log("need new Login")
            // router.replace('/login')   
         
            //var provider = new firebase.auth.OAuthProvider('microsoft.com');
           //firebase.auth().signInWithRedirect(provider);
          }
        })
    },
    showBoolean({commit},{text,color}){
      
      commit('SET_MSGSNACKBAR')
      commit('SET_MSGSNACKBAR_TEXT',text)
      commit('SET_MSGSNACKBAR_COLOR',color)
      setTimeout(() => {
        commit('SET_MSGSNACKBAR')
      }, 3000);
    },
    async performLogin({ commit, dispatch }, { email, password}) {
      const user = await firebase.auth().signInWithEmailAndPassword(email, password)
      .catch(function(error) {
        dispatch('showBoolean',{text:error.message,color:'error'})
      });
      console.log(user);
      //commit('SET_USER', );
    },
    async resetPassword({commit ,dispatch}, {email}){
      firebase.auth().sendPasswordResetEmail(email)
      .then(function (){
        dispatch('showBoolean',{text:'Link zum Zurücksetzen des Passworts gesendet an: ' + email,color:'success'})
      })
      .catch(function (error) {
        dispatch('showBoolean',{text:'Mit dieser E-Mail-Adresse ist kein Konto verbunden',color:'error'})
      })
    },
    async signUp({ commit, dispatch }, { email, password ,name}) {
     /* const user = */
     await firebase.auth().createUserWithEmailAndPassword(email, password).then(function(result) {
        return result.user.updateProfile({
          displayName: name
        })
      }).catch(function(error) {
        dispatch('showBoolean',{text:error,color:'error'})
      });
      console.log(firebase.auth().currentUser);
    },  
    async signOut({commit}){
      firebase.auth().signOut().then(() => {
        commit('SET_USER', null);
        router.replace('/login')
      })
    }
};
const namespaced = true;
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
 }