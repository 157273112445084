// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBu2Oy_TJEbGt2XkpnS5sCDWF7WyBUz920",
  authDomain: "sc-kalender-fa94b.firebaseapp.com",
  projectId: "sc-kalender-fa94b",
  storageBucket: "sc-kalender-fa94b.appspot.com",
  messagingSenderId: "391343279774",
  appId: "1:391343279774:web:0c8efd791890bf68a3758f",
  measurementId: "G-7YHQ9Y4QJG"
};

  export default firebaseConfig
