import moment from 'moment-business-days';
//import holidays from '@/dates';
import { firestoreAction } from 'vuexfire';
import {db} from './../../fb'; 
import dates from '@/dates';

const namespaced = true;
const state = {
   // Dates for 16. in each month
   startDate: moment("2020-01-01T00:00:00.000Z").startOf('month'),
   endDate : moment("2026-01-01T00:00:00.000Z"),
   // Holidays
   bdewDates:[{
    bdewDates:[{ holidayDate: "2022-12-31", description: "Silvester" }],
  }],
  choosenDate: new Date(),

 };
 const getters = {

    eachMonthWith16(state){
      return state.eachMonthWith16
    },
    getBdewDates(state){
      
      return state.bdewDates 
    },

   attributes(state,getters,rootState, rootGetters){
  var settings =  {
      redOnOff: true,
     }
    
  return [
    // Alle BDEW Feiertage
    {
      key: 'bdewFeiertage',
      // highlight: this.redOnOff ? {class: 'red darken-1'} : false,
       highlight: settings.redOnOff ? {color: 'blue',fillMode: 'light'} : false,
      popover: {
        label: 'BDEW Feiertag',
        hideIndicator: true,
        placement: 'right',
      },
      dates: getters.getBdewDates.map(i => i.holidayDate)
      
    },

   {
      key: 'todos0',
      bar:{class: 'red'},
      dates: rootGetters['todoStore/redTodoGetter'].map(i => i.termin),
      popover: {
        label: 'Sehr hoch',
        hideIndicator: true,
        placement: 'right',
      },
    },
    {
      key: 'todos1',
      bar:{class: 'amber'},
      dates: rootGetters['todoStore/amberTodoGetter'].map(i => i.termin),
      popover: {
        label: 'Hoch',
        hideIndicator: true,
        placement: 'right',
      },
    },
    {
      key: 'todos2',
      bar:{class: 'green'},
      dates: rootGetters['todoStore/greenTodoGetter'].map(i => i.termin),
      popover: {
        label: 'Normal',
        hideIndicator: true,
        placement: 'right',
      },
    },
    {
      key: 'todos3',
      bar:{class: 'blue'},
      dates: rootGetters['todoStore/blueTodoGetter'].map(i => i.termin),
      popover: {
        label: 'Gering',
        hideIndicator: true,
        placement: 'right',
      },
    },
    {
      key: 'today',
      highlight: {
        color: 'green',
        fillMode: 'light'
      },
      dates: new Date(),
    },
  ]
   },
   getChoosenDate(state){
    return state.choosenDate
   },
 };
 const mutations = {
 SET_BDEWDATES(state, dates){
   state.bdewDates = dates 
 },
 SET_CHOOSEN_DATE(stat, date){
  state.choosenDate = date
 },
};
 const actions = {
   loadBdewDates({commit, dispatch}){ 
    commit('SET_BDEWDATES', dates);
   }, 
   setDate({commit},date){
    commit('SET_CHOOSEN_DATE', date)
   },
};

 export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
 }