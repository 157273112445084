<template>
  <v-app  :style="{background: $vuetify.theme.themes[theme].background}">
    <div id="home_content">
    <!--  <div id="nav">
        <router-link to="/">Dashboard</router-link> |
        <router-link to="/configurations">Einstellungen</router-link>
      </div> -->
     
      <TopNavbar/>
      <v-overlay :value="isPageLoading">
        <v-progress-circular :size="70" :width="10" color="primary" indeterminate></v-progress-circular>
      </v-overlay>
      <v-main>
        <v-container>
          
              <router-view ></router-view>
           
        </v-container>
      </v-main>
     
      <!-- <router-view/> -->
    </div> 
  </v-app>
</template>


<script>
import TopNavbar from '@/components/TopNavbar.vue'
export default {
  name: 'App',
  components: {
    TopNavbar
  },
  data(){
    return {
      loadedSetting: false
    }
  },
  computed: {
    isPageLoading(){
      return this.$store.getters["uiStore/isPageLoading"];
    },
    //mapGetters({isPageLoading: "ui/isPageLoading"}),
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }, 
    // setting(){
    //     var val = this.$store.getters['settingsStore/getSetting']
    //     if (val != undefined){
    //     this.$vuetify.theme.dark = val.nightMode
    //     } 
    //     return val 
    //   }, 
      loginStatus(){
        return this.$store.getters['authStore/getLoginStatus']
      } 
  },
  methods:{
     logHeights(){
       var body = document.getElementById("home_content")
      // console.log(body.scrollHeight)
        window.parent.postMessage(body.scrollHeight, '*');
    },
  },
  mounted(){
  window.addEventListener("resize", this.logHeights);
    },
    updated(){
      this.logHeights()
    },
  beforeCreate(){
    
    this.$store.dispatch('authStore/logIn');
    //this.$store.dispatch('dateStore/eachMonthWith16');
    //this.$store.dispatch('dateStore/loadDateRules');
    
  },
  watch:{
     setting(){
        
        
      },
      loginStatus(){
        
      }
  }
  
};
</script>


<style>
.v-application {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
