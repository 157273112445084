import firebaseConfig from './firebaseConfig';
//Firebase --- Start 
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firebase-analytics';
import 'firebase/firestore';

  
  // Initialize Firebase
 const app = firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  

// Get a Firestore instance

export const db = app.firestore();

db.enablePersistence()
  .catch(err => {
    if(err.code == 'failed-precondition'){
       
      console.log('persistence failed');
    } else if (err.code == 'unimplemented'){
      //lack of browser support
      console.log('persistence is not available')
    }
  })
// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
//db.settings({ timestampsInSnapshots: true 
//});

// Firebase --- End
