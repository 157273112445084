<template>
    <div class="">
        <v-dialog
      v-model="dialog"
      persistent
      max-width="290" 
    >
        
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-right "
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                    
                    >
                    <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn> 
                 </template>
                 
            <v-card class="pa-0" elevation="0">
              <v-card-title>
                  
                      Notiz löschen
                  
                  
              </v-card-title>
              <v-card-text>
                <div>
                    Sie sind im Begriff eine Notiz zu löschen. Möchten Sie diese Notiz unwiderruflich löschen?
                </div>
                 </v-card-text>
                 <v-card-actions>
                     <v-tooltip bottom>
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-right mb-5 mx-auto"
                      fab
                      dark
                      v-bind="attrs"
                      v-on="on"
                      small
                      depressed
                      color="primary"
                     @click="deleteTodo(todo)"
                     
                     
                    >
                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn> 
                 </template>
                 <span>Löschen bestätigen</span>
                </v-tooltip>
                <v-tooltip bottom>
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-right mb-5 mx-auto"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      @click="dialog = false"
                     
                    >
                    <v-icon>mdi-cancel</v-icon>
                    </v-btn> 
                 </template>
                 <span>Abbrechen</span>
                </v-tooltip>
                

            
                  
                
              </v-card-actions>
            </v-card>
        </v-dialog>            
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name:'RemoveTodo',
    props:['todo'],
    data() { 
      return {
          dialog: false
        // todos: []

      }
    },
    methods: {
     ...mapActions('todoStore',[
        'removeTodo',
      ]),
      deleteTodo(todo){
        this.removeTodo(todo)
        this.$emit("closeMe")
        this.dialog = false
      }
    },
}
</script>