import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
        //primary: '#009dce',
        primary: '#155b66',
        secondary: '#2b6ea9',
        accent: '#275c9b',
        error: '#b71c1c',
        primaryLight: '#009dce',
        highlight:'#a0ce4e'
        },
        dark: {
          //primary: '#009dce',
          primary: '#155b66',
          secondary: '#009dce',
          accent: '#275c9b',
          error: '#f34848',
          primaryLight: '#fff',
          highlight:'#a0ce4e'
          }
        },
      },
});
