<template>
<v-app>
  <v-row align="center" justify="center">  
      <v-card
        class="mx-auto"
        width="620"
        height="auto"
        color='#EAEAEA'
      >
      <v-form ref="form">
        <v-card-title class="justify-center">ANMELDEN</v-card-title>
        <v-text-field
        class="mx-9"
        name="email"
        label="E-Mail"
        id="email"
        v-model="email"
        type="email"
        placeholder=" "
        persistent-placeholder
        :rules="emailRules"
        ></v-text-field>
        <v-text-field
        class="mx-9"
        name="password"
        label="Passwort"
        id="password"
        v-model="password"
        type="password"
        placeholder=" "
        persistent-placeholder
        :rules="passwordRules"
        ></v-text-field>
        <v-row class="mx-4 mb-4 mt-2">
        <v-btn class="mx-3 mb-4" color="primary" @click="go">Anmelden</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mx-3 mb-4" color="primary" text @click="newPassword">Passwort vergessen</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mx-3 mb-4 highlight--text" text @click="register">Registrieren</v-btn>
        </v-row>
        </v-form>
      </v-card>

   
  <MsgSnackbar/>
  </v-row> 
      
      
  </v-app> 
</template>

<script>

import MsgSnackbar from '@/components/MsgSnackbar'
export default {
  name: 'Login',
 components:{
    MsgSnackbar
  },
  data() {
    return {
        email: "",
        password: "",
        passwordRules: [
        v => !!v || 'Dies ist ein Pflichtfeld'
      ],
        emailRules: [
        v => !!v || 'Dies ist ein Pflichtfeld',
        v => /.+@.+/.test(v) || 'Die E-Mail muss gültig sein',
      ],
    };
  },
  methods: {
    go() {
      if(this.$refs.form.validate()){
        this.$store.dispatch('authStore/performLogin',{email:this.email,password:this.password})}
      else{
          setTimeout(() => {
            this.$refs.form.resetValidation()
          }, 2000);        
        }  
    },
    register(){
      this.$router.push("signup")
    },
    newPassword(){
      this.$router.push('reset-password')
    }
  },
  mounted(){
  }
};
</script>

<style scoped>
.sign{
  color: black;
  text-decoration: none;
}
</style>
