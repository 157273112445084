<template>
<div class="">
   <v-snackbar
        :timeout="timeout"
        :value="msgSnackbar"
        bottom
        center
        :color="msgSnackbarColor"
      >
        {{ msgSnackbarText }} 
        
     <v-progress-linear
      absolute
      bottom 
      color="white"
      :value="Math.floor(100 * (currentTime / timeout))"
    />
    </v-snackbar>
      
      </div>
</template>

<script>
export default {
    name:'MsgSnackbar',
    data() {
        return {
            currentTime: 200,
            timeout: 3000
        }
    },
     computed:{
    msgSnackbar(){
        let bool = this.$store.getters['authStore/getMsgSnackbar']
        if (bool) {
            this.syncPbar()
        }
      return bool
    },
    msgSnackbarText(){
        return this.$store.getters['authStore/getMsgSnackbarText']
    },
    msgSnackbarColor(){
        return this.$store.getters['authStore/getMsgSnackbarColor']
    }
  },
  methods:{
      syncPbar() {
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //Increment the time counter by 100
        this.currentTime += 100;
        //If our current time is larger than the timeout
        if (this.timeout <= this.currentTime) {
          //Wait 300 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.currentTime = 200; // reset the current time
          }, 300);
        } else {
          //Recursivly update the progress bar
          this.syncPbar();
        }
      }, 100);
  }
  }
}
</script>