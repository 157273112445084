<template>
  <div>
    <v-app-bar
      :color="isNightModeOn"
      dark
      elevation="0"
     
    >
       <!-- height="80px" from 6th row -->
      <v-spacer></v-spacer>
      <!-- <div class="mx-auto" :style="getFontSizeByBreakpoint">Slenderiser Kalender</div> -->
      <base-heading class="mx-auto my-auto">Slenderiser Kalender</base-heading>
      <v-spacer></v-spacer>
    
        <v-menu v-if="loginStatus" offset-y>
        <template v-slot:activator="{ on }">

    <v-btn color="secondary" fab elevation="0" small v-on="on">
      <span class="white--text headline" v-text="nameInitials"></span>
    </v-btn> 

        </template>
        <v-list>
          <v-list-item dense
          @click="signOut"
          >
            <v-list-item-title>Abmelden</v-list-item-title>
            <v-list-item-icon>
            <v-icon >mdi-logout</v-icon>
          </v-list-item-icon>
          </v-list-item>
        </v-list>
          
      </v-menu>
    </v-app-bar>

    
    
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
import BaseHeading from './base/BaseHeading.vue';
  export default {
  components: { BaseHeading },
    data: () => ({
      drawer: false,
      group: null,
    }),

    computed: {
      getFontSizeByBreakpoint() {
        let val ="";
        switch (this.$vuetify.breakpoint.name)
        {
          case 'xs':
          val="font-size: 16px !important; font-family: 'Open Sans' !important; font-weight: 400; font-style: normal;"
          break;
          case 'sm':
          val="font-size: 16px !important; font-family: 'Open Sans' !important; font-weight: 400; font-style: normal;"
          break;
          case 'md':
          val="font-size: 20px !important; font-family: 'Open Sans' !important; font-weight: 400; font-style: normal;"
          break;
          case 'lg':
          val="font-size: 24px !important; font-family: 'Open Sans' !important; font-weight: 400; font-style: normal;"
          break;
          case 'xl':
          val="font-size: 28px !important; font-family: 'Open Sans' !important; font-weight: 400; font-style: normal;"
          break;
        }
        return val
      },
      ...mapGetters(
        {"userName": "authStore/userName", "user":"authStore/getUser"}),
      isNightModeOn(){
        var val = ''
        if (this.$vuetify.theme.dark == true){
          val = '#1E1E1E'
        } else {
          val = 'primary'
        }
        return val
      },
       loginStatus(){
      let bool = false
      if (this.user.data) {
        bool = true
      }
      return bool
    },
      nameInitials() {
        const value = this.userName;
        if (value != null) {
          if (value.length > 1) {
            var result = "";
            var tokens = value.split(/\s/);
            for (var i = 0; i < tokens.length; i++) {
              result += tokens[i].substring(0, 1).toUpperCase();
            }
            if (result.length == 2) {
              return result.substring(1, 2) + result.substring(0, 1);
            } else if (result.length > 2) {
              return result.substring(1, 2) + result.substring(0, 1);
            }
            return result;
          } else {
            return value;
          }
        } else {
          return "?";
        }
      },

    },

    methods: {
      signOut() {
        this.$store.dispatch('authStore/signOut')
      }
    },
  }
</script>