const state = {
    pageLoading: {},
    showDialog: false,
};
const namespaced = true;
const getters = {
    isPageLoading: ({ pageLoading }) => Object.values(pageLoading).includes(true),
    getShowDialog(state){
        return state.showDialog;
    }
};

const actions = {
    startPageLoading({ commit }, scope) {
        commit('SET_PAGE_LOADING', { scope, isLoading: true });
    },
    stopPageLoading({ commit }, scope) {
        commit('SET_PAGE_LOADING', { scope, isLoading: false });
    },
    showDialog({commit}){
        commit('SET_SHOW_DIALOG', true)
    },
    hideDialog({commit}){
        commit('SET_SHOW_DIALOG', false)
    }
};

const mutations = {
    SET_PAGE_LOADING(state, { scope, isLoading }) {
        // clone the object, so mutating it will cause a 
        const newPageLoading = {};
        Object.keys(state.pageLoading).forEach(key => newPageLoading[key] = state.pageLoading[key])
        // change the value for scope
        newPageLoading[scope] = isLoading;
        state.pageLoading = newPageLoading
    },
    SET_SHOW_DIALOG(state, val){
        state.showDialog = val
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
};